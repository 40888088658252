import { ActionTypeNames } from '../components/form/ActionTypes';
import { ClientForm } from './ClientForm';

export type IngestImportFileResponse = {
  jobId: string;
  status: DataJobStatus;
};

export type DataImportJobResponse = {
  jobId: string;
  jobStatus: DataJobStatus;
};

export enum DataJobStatus {
  NotStarted = 0,
  Setup = 1,
  InProgress = 2,
  Completed = 3,
  Failed = 4,
  Cancelled = 7,
}

export enum DataJobSourceType {
  Module = 0,
  Resource = 1,
  Activity = 2,
}

export type DataJobHeader = {
  text: string;
  columnIndex: number;
  displayOrder: number;
  actionId: string | null;
  sectionId: string | null;
  dataAction: ImportAction;
  actionType: ActionTypeNames | null;

  phoneCountryCode: string | null;
  currencyCode: string | null;
  yesValues: string[] | null;
  noValues: string[] | null;
  notApplicableValues: string[] | null;
};

export type DataImportProperties = {
  headerRowIndex: number;
  dataRowIndex: number;
  titleColumnIndex: number;
  importType: DataJobType;
  sheetIndex: number;
  templateId: string;
  templateName?: string;
  clientId?: string;
};

export enum ImportAction {
  Map = 0,
  CreateNew = 1,
  Ignore = 2,
}

export enum DataJobType {
  HeadersOnly = 0,
  HeadersAndData = 1,
  DataOnly = 2,
}

export type DataImportColumnValues = {
  values: string[];
};

export type DataImportValidateColumn = {
  templateFormId: string;
  headers: DataJobHeader[];
  dataRowIndex: number;
  dataSheetIndex: number;
};

export type DataImportPreviewRequest = {
  targetRowIndex: number;
  dataSheetIndex: number;
};

export type DataImportValidationResult = Record<number, DataImportFailureMessage[]>;

export type DataImportFailureMessage = {
  isSuccess: boolean;
  row: number;
  column: number;
  answer: string;
  actionType: ActionTypeNames;
};

export type ImportPreviewResponse = ClientForm & {
  totalRecordCount: number;
  previousRowIndex: number | null;
  nextRowIndex: number | null;
};
